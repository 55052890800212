/**
 * Inservice Waiting Room
 * Inservice waiting-room API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { FindByHashDto } from '../model/findByHashDto';
// @ts-ignore
import { ResendPatientNotificationDto } from '../model/resendPatientNotificationDto';
// @ts-ignore
import { StartWaitingRoomDto } from '../model/startWaitingRoomDto';
// @ts-ignore
import { SubscriptionTokenDto } from '../model/subscriptionTokenDto';
// @ts-ignore
import { UpdateWaitingRoomDto } from '../model/updateWaitingRoomDto';
// @ts-ignore
import { WaitingRoomDto } from '../model/waitingRoomDto';
// @ts-ignore
import { WaitingRoomStatus } from '../model/waitingRoomStatus';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class WaitingRoomService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
  * @param consumes string[] mime-types
  * @return true: consumes contains 'multipart/form-data', false: otherwise
  */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async waitingRoomControllerCancel(xTENANTID: string, id: string, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerCancel.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerCancel.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/cancel/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async waitingRoomControllerClose(xTENANTID: string, id: string, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerClose.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerClose.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/close/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param startDate 
   * @param endDate 
   * @param timezone 
   * @param status 
   * @param frontDoorId Front door id
   */
  public async waitingRoomControllerFind(xTENANTID: string, startDate: string, endDate: string, timezone: string, status?: WaitingRoomStatus, frontDoorId?: string, ): Promise<Array<WaitingRoomDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerFind.');
    }
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling waitingRoomControllerFind.');
    }
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling waitingRoomControllerFind.');
    }
    if (timezone === null || timezone === undefined) {
      throw new Error('Required parameter timezone was null or undefined when calling waitingRoomControllerFind.');
    }

    let localVarQueryParameters: any = {};
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters['startDate'] = startDate;
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters['endDate'] = endDate;
    }
    if (timezone !== undefined && timezone !== null) {
      localVarQueryParameters['timezone'] = timezone;
    }
    if (status !== undefined && status !== null) {
      localVarQueryParameters['status'] = status;
    }
    if (frontDoorId !== undefined && frontDoorId !== null) {
      localVarQueryParameters['frontDoorId'] = frontDoorId;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/filter`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param frontDoorId Front door id
   */
  public async waitingRoomControllerFindAllOpen(xTENANTID: string, frontDoorId: string, ): Promise<Array<WaitingRoomDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerFindAllOpen.');
    }
    if (frontDoorId === null || frontDoorId === undefined) {
      throw new Error('Required parameter frontDoorId was null or undefined when calling waitingRoomControllerFindAllOpen.');
    }

    let localVarQueryParameters: any = {};
    if (frontDoorId !== undefined && frontDoorId !== null) {
      localVarQueryParameters['frontDoorId'] = frontDoorId;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/all-open`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async waitingRoomControllerFindById(xTENANTID: string, id: string, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerFindById.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerFindById.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param findByHashDto 
   */
  public async waitingRoomControllerFindOneByHash(xTENANTID: string, findByHashDto: FindByHashDto, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerFindOneByHash.');
    }
    if (findByHashDto === null || findByHashDto === undefined) {
      throw new Error('Required parameter findByHashDto was null or undefined when calling waitingRoomControllerFindOneByHash.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/hash`,
        data: findByHashDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param fileId 
   */
  public async waitingRoomControllerGetFile(xTENANTID: string, id: string, fileId: string, ): Promise<Blob> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerGetFile.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerGetFile.');
    }
    if (fileId === null || fileId === undefined) {
      throw new Error('Required parameter fileId was null or undefined when calling waitingRoomControllerGetFile.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async waitingRoomControllerGetToken(xTENANTID: string, id: string, ): Promise<SubscriptionTokenDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerGetToken.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerGetToken.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/refresh/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param fileId 
   */
  public async waitingRoomControllerRemoveFile(xTENANTID: string, id: string, fileId: string, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerRemoveFile.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerRemoveFile.');
    }
    if (fileId === null || fileId === undefined) {
      throw new Error('Required parameter fileId was null or undefined when calling waitingRoomControllerRemoveFile.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param resendPatientNotificationDto 
   */
  public async waitingRoomControllerResendLink(xTENANTID: string, id: string, resendPatientNotificationDto: ResendPatientNotificationDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerResendLink.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerResendLink.');
    }
    if (resendPatientNotificationDto === null || resendPatientNotificationDto === undefined) {
      throw new Error('Required parameter resendPatientNotificationDto was null or undefined when calling waitingRoomControllerResendLink.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/resend/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: resendPatientNotificationDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param startWaitingRoomDto 
   */
  public async waitingRoomControllerStart(xTENANTID: string, id: string, startWaitingRoomDto: StartWaitingRoomDto, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerStart.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerStart.');
    }
    if (startWaitingRoomDto === null || startWaitingRoomDto === undefined) {
      throw new Error('Required parameter startWaitingRoomDto was null or undefined when calling waitingRoomControllerStart.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/start/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: startWaitingRoomDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param updateWaitingRoomDto 
   */
  public async waitingRoomControllerUpdate(xTENANTID: string, id: string, updateWaitingRoomDto: UpdateWaitingRoomDto, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerUpdate.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerUpdate.');
    }
    if (updateWaitingRoomDto === null || updateWaitingRoomDto === undefined) {
      throw new Error('Required parameter updateWaitingRoomDto was null or undefined when calling waitingRoomControllerUpdate.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/update/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: updateWaitingRoomDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param file 
   * @param name 
   */
  public async waitingRoomControllerUploadFile(xTENANTID: string, id: string, file: Blob, name: string, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling waitingRoomControllerUploadFile.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling waitingRoomControllerUploadFile.');
    }
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling waitingRoomControllerUploadFile.');
    }
    if (name === null || name === undefined) {
      throw new Error('Required parameter name was null or undefined when calling waitingRoomControllerUploadFile.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }
      // to determine the Content-Type header
      const consumes: string[] = [
        'multipart/form-data'
      ];
    const canConsumeForm = this.canConsumeForm(consumes);
    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }
    if (file !== undefined) {
      localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
    }
    if (name !== undefined) {
      localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
          data: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
