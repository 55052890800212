/**
 * Inservice Waiting Room
 * Inservice waiting-room API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { CancelWaitingRoomDto } from '../model/cancelWaitingRoomDto';
// @ts-ignore
import { CreateWaitingRoomFromExistingDto } from '../model/createWaitingRoomFromExistingDto';
// @ts-ignore
import { FindByHashDto } from '../model/findByHashDto';
// @ts-ignore
import { PatientWaitingRoomInfoDto } from '../model/patientWaitingRoomInfoDto';
// @ts-ignore
import { SubscriptionTokenDto } from '../model/subscriptionTokenDto';
// @ts-ignore
import { UpdatePatientDataDto } from '../model/updatePatientDataDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class WaitingRoomPatientAccessService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param xTENANTID 
   * @param id 
   * @param cancelWaitingRoomDto 
   */
  public async patientWaitingRoomInfoControllerCancel(xTENANTID: string, id: string, cancelWaitingRoomDto: CancelWaitingRoomDto, ): Promise<PatientWaitingRoomInfoDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientWaitingRoomInfoControllerCancel.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientWaitingRoomInfoControllerCancel.');
    }
    if (cancelWaitingRoomDto === null || cancelWaitingRoomDto === undefined) {
      throw new Error('Required parameter cancelWaitingRoomDto was null or undefined when calling patientWaitingRoomInfoControllerCancel.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/info/cancel/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: cancelWaitingRoomDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param createWaitingRoomFromExistingDto 
   */
  public async patientWaitingRoomInfoControllerCreate(xTENANTID: string, createWaitingRoomFromExistingDto: CreateWaitingRoomFromExistingDto, ): Promise<PatientWaitingRoomInfoDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientWaitingRoomInfoControllerCreate.');
    }
    if (createWaitingRoomFromExistingDto === null || createWaitingRoomFromExistingDto === undefined) {
      throw new Error('Required parameter createWaitingRoomFromExistingDto was null or undefined when calling patientWaitingRoomInfoControllerCreate.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/info`,
        data: createWaitingRoomFromExistingDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param findByHashDto 
   */
  public async patientWaitingRoomInfoControllerFindOneByHash(xTENANTID: string, findByHashDto: FindByHashDto, ): Promise<PatientWaitingRoomInfoDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientWaitingRoomInfoControllerFindOneByHash.');
    }
    if (findByHashDto === null || findByHashDto === undefined) {
      throw new Error('Required parameter findByHashDto was null or undefined when calling patientWaitingRoomInfoControllerFindOneByHash.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/info/hash`,
        data: findByHashDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientWaitingRoomInfoControllerGetToken(xTENANTID: string, id: string, ): Promise<SubscriptionTokenDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientWaitingRoomInfoControllerGetToken.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientWaitingRoomInfoControllerGetToken.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/info/refresh/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param updatePatientDataDto 
   */
  public async patientWaitingRoomInfoControllerUpdatePatientData(xTENANTID: string, id: string, updatePatientDataDto: UpdatePatientDataDto, ): Promise<PatientWaitingRoomInfoDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientWaitingRoomInfoControllerUpdatePatientData.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientWaitingRoomInfoControllerUpdatePatientData.');
    }
    if (updatePatientDataDto === null || updatePatientDataDto === undefined) {
      throw new Error('Required parameter updatePatientDataDto was null or undefined when calling patientWaitingRoomInfoControllerUpdatePatientData.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    localVarHeaders['X-USER-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/waiting-room/waiting-room/info/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: updatePatientDataDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
